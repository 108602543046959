export const MINIMUM_SEARCH_CHARACTERS = 0

export const WEEKS_IN_YEAR = 52.1429

export const TOTAL_FEE = 0.0025
export const LP_HOLDERS_FEE = 0.0017
export const TREASURY_FEE = 0.0008 // The total fee is 0.08%
export const BUYBACK_FEE = 0.0005 // The buyback is disabled for now - this needs to be added in AdvancedSwapDetails and SwapModalFooter

export const CHART_START_TIME = 1701352800 // 1st of December 2023
export const ONE_DAY_UNIX = 86400 // 24h * 60m * 60s
export const ONE_HOUR_SECONDS = 3600

export const ITEMS_PER_INFO_TABLE_PAGE = 10

// These tokens are either incorrectly priced or have some other issues that spoil the query data
// None of them present any interest as they have almost 0 daily trade volume
export const TOKEN_BLACKLIST = [
  // These ones are copied from v1 info
  '0xf6d46849DB378AE01D93732585BEc2C4480D1fD5', // FORT
]
